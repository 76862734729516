%flex {
  display: flex;
}

%flex-col {
  display: flex;
  flex-direction: column;
}

%center {
  display: flex;
  align-items: center;
  justify-content: center;
}