@import '../../styles/placeholders';
@import '../../styles/variables';
@import '../../styles/mixins';

section#contact-page {
  @include page-layout;
  width: 100%;
  display: flex;
  font-size: 14px;
  color: white;
  font-family: $poppins;

  @media (max-width: 767px) {
    font-size: 10px;
  }

  .form-container {
    padding: 3%;
    background: #acacac;
    width: 50%;

    h3 {
      font-size: 2em;
    }

    p {
      font-size: 1.2em;
    }

    form {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      label {
        font-size: 1.4rem;
        width: 100%;
      }

      input:not([type="submit"]),
      textarea {
        width: 100%;
        outline: none;
        color: white;
        padding: 2%;
        font-size: 20px;
        background: inherit;
        border-bottom: 4px solid white;
        margin-bottom: 20px;
      }

      textarea {
        height: 250px;
      }

      #contact-btn {
        height: 50px;
        border-radius: 5px;
        padding: 10px 40px;
        background: white;
        display: block;
        width: 30%;
        font-size: 1.2em;
        color: $phil-blue;
        margin-bottom: 1.3rem;
      }

      .contact-info {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        color: white;
        width: 100%;
        margin: 5px 0;

        svg {
          font-size: 2em;
          padding: 5px;
          width: auto;
          border-radius: 50%;
          border: 1px solid white;
          margin-right: 5px;
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }

  }

  .image-container {
    background: url("../../media/phil-and-mq.jpg");
    background-position: center;
    background-size: cover;
    filter: brightness(.9);
    width: 50%;

    @media (max-width: 767px) {
      display: none;
    }
  }
}