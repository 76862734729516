@import '../../styles/_mixins';
@import '../../styles/_placeholders';
@import '../../styles/_variables';

section#events {

  img {
    width: 200px;
  }

  .events-hero {
    position: relative;
    width: 100%;
    min-height: 74vh;
    background: url('../../media/phil-and-friends.jpg');
    background-size: cover;
    background-position-y: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5%;
    color: white;

    @media (max-width:700px) {
      background-position-x: 10%;
    }


    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.746);
    }

    *:not(.overlay) {
      position: relative;
    }

    h2 {
      font-size: 1.875rem;
      font-weight: normal;
      font-family: $poppins;
      font-style: italic;

      @media (max-width:1000px) {
        text-align: center;
      }

      @media (max-width:700px) {
        display: none;
      }
    }

    h1 {
      color: white;
      font-size: 4rem;
      font-family: $poppins;

      @media (max-width:700px) {
        margin: 25px 0;
      }
    }

    a {
      @include btn(white, $phil-blue);
      color: $phil-blue;
      padding: 5px 10px;

      @media (max-width:700px) {
        padding: 10px;
      }
    }
  }

  .previously {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $poppins;
    color: $phil-blue;

    h1 {
      font-size: 40px;
      font-weight: normal;
      width: 100%;
      font-style: italic;
      font-family: $poppins;
      font-size: 2.5rem;
      padding-left: 20px;

      @media (max-width:700px) {
        text-align: center;
      }
    }

    h2 {
      margin: 15px 0;
      font-size: 2.5rem;
      max-width: 60%;
      text-align: center;

      @media (max-width:700px) {
        text-align: center;
        font-size: 1.8rem;
      }
    }

    &>div:last-child {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: row;
      margin-bottom: 5vh;

      &>div {
        margin: 0 auto;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p:first-child {
          font-size: 1.875rem;
          text-align: center;
          color: #717171;
        }

        p:last-child {
          font-size: 2.5rem;
          font-weight: bold;
        }

        @media (max-width:700px) {
          margin: 15px auto;
        }
      }

      @media (max-width:700px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .event-stats {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;


      .stat {
        font-family: $poppins;
        text-align: center;
        width: 300px;

        .desc {
          font-size: 30px;
          color: #717171;
        }

        .data {
          font-size: 40px;
          font-weight: bold;
          font-style: italic;
        }
      }
    }

    .slider-container {
      .carousel {
        img {
          height: 700px;
          object-fit: cover;
          object-position: center;
          filter: brightness(.5);
        }
      }
    }

    .greeting {
      font-weight: normal;
      font-size: 30px;
      font-style: italic;
      margin-top: 30px;

      @media (max-width:700px) {
        font-size: 18px;
      }
    }
  }

  .upcoming {
    padding: 0 20px;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2 {
      font-family: $poppins;
      font-size: 2.5rem;

      @media (max-width:700px) {
        text-align: center;
      }
    }

    h2:first-child {
      font-weight: normal;
      font-style: italic;
      color: $phil-blue;
    }

    p {
      color: gray;
      font-size: 25px;
      margin: 5px auto;
      text-align: center;
      font-family: $poppins;
    }

    a {
      @include btn($phil-blue, white);
      padding: 5px 10px;
      display: block;
      margin: 15px auto;
      width: fit-content;
    }


  }

  .event {
    margin: 25px auto;
    height: 325px;
    width: 619px;
    display: flex;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .date {
      width: 215px;
      height: 100%;
      background: #958FA7;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 4.375rem;
        font-weight: normal;
        font-family: $poppins;
      }
    }

    .content {
      width: 404px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 40%;
        object-fit: cover;
        margin: 0;
        padding: 0;
      }

      .info {
        min-height: 55%;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        align-items: center;
        font-family: $poppins;
        padding: 5px;

        h4 {
          color: $phil-blue;
          text-align: center;
          font-size: 1.75rem;
        }

        &>p {
          font-size: 1.25rem;
        }

        span {
          padding: 10px 0;
          width: 100%;
          display: flex;
          justify-content: center;

          p {
            font-size: 1.25rem;
            width: 50%;
            color: #656565;
          }

          button {
            background: none;
            width: auto;
            margin-right: 3px;

            svg {
              color: gray;
              font-size: 2rem;
            }
          }
        }

        .attachments {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;

          a {
            margin: 0 5px;
          }
        }
      }

    }

    @media (max-width:700px) {
      width: 100%;
    }

  }

  #our-events {
    background: #958FA7;
    min-height: 100vh;
    padding: 1%;

    .event-container {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      align-items: center;
      justify-content: center;

      .event-info {
        background: white;
        border-radius: 30px;
        min-height: 615px;
        width: 524px;
        margin: 5%;
        padding: 25px;
        font-family: $poppins;

        @media (max-width:700px) {
          width: 100%;
          margin: 0;
          margin-bottom: 20px;
        }


        .icon-container {
          height: 175px;
          width: 175px;
          background: #958FA7;
          margin: 0 auto;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;

          img {
            height: 117px;

          }

          &#cornhole {
            img {
              transform: rotate(-40deg);
            }
          }
        }

        h3 {
          font-size: 25px;
          color: $phil-blue;
          text-align: center;
          margin: 5px auto;
        }

        p {
          font-size: 18px;
          margin-top: 30px;
        }
      }
    }
  }

}