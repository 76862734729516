@import '../styles/placeholders';
@import '../styles/variables';

footer#custom-footer {
  background: $phil-blue;
  padding: 25px 0;
  position: relative;

  .flex-row {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .flex-row:nth-child(1) {
    a {
      color: white;
      text-decoration: none;
      font-family: $poppins;
      font-size: 1.56rem;
      padding: 5px 15px;
      text-transform: uppercase;
    }
  }

  .flex-row:nth-child(2) {
    margin: 0 10px;

    a {
      margin: 0 10px;

      img {
        height: 100%;
        width: auto;
        display: block;
      }
    }

    p {
      color: white;
      font-size: 1.56rem;
      font-family: $poppins;
      margin: 10px 0;
    }
  }

  .flex-row:nth-child(3) {
    p {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      font-family: $poppins;
      text-align: center;

      @media(max-width: 500px) {
        margin: 1rem auto;
      }
    }
  }

}