@import "../../styles/_variables";

#event-countdown-container {
  background: $phil-blue;
  color: red;
  display: flex;
  align-items: center;
  padding: 10px 5%;
  position: relative;
  z-index: 2;

  &>div,
  &>h1 {
    height: 100%;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    .time-container {
      font-family: $knewave;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        width: 20%;
        margin: 0 2%;
      }

      .time {
        color: $phil-blue;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        border-radius: 15px;
        height: 60px;
        width: 77px;
        margin: 0 10px;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .metric {
        color: white;
        font-size: 1rem;
        width: 77px;
        text-align: center;
      }
    }
  }

  .middle {
    font-size: 20px;
    flex: 1;
    text-align: center;
    font-family: $knewave;
    color: white;

    @media (max-width: 767px) {
      font-size: 1rem;
      margin: 5px 0;
    }
  }

  .right {

    a {
      text-decoration: none;
      color: $phil-blue;
      background: white;
      font-size: 1.4rem;
      border-radius: 30px;
      padding: 15px 30px;
      font-family: $knewave;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    font-size: small;
    height: 200px;
  }
}