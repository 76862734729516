@import './variables';

//Resetting to default styling
* {
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


footer {
  margin-bottom: 0;
}


.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: none;
}

button {
  cursor: pointer;
}

h4 {
  font-size: 1.5rem;
}

//global variables
$page-width: 1200px;


//media styles

@media only screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }
}

@media only screen and (max-width: 700px) {
  :root {
    font-size: 12px;
  }
}

// global styles
.underline-title {
  font-family: $arimo;
  color: $phil-blue;
  font-size: 40px;
  text-align: center;
  margin: 70px 0;
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
  font-style: italic;

  &::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 3px;
    background: $phil-blue;
    bottom: -10px;
  }
}