@import '../../styles/_mixins';
@import '../../styles/_variables';
@import '../../styles/_placeholders';

section#about {
  font-family: $bree;
  @include page-layout;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &>div {
    padding: 5% 2%;
    margin: 0 auto;
  }

  #about-hero {
    width: 100%;
    height: 74vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    position: relative;

    .overlay {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -5;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: .7;
        z-index: 10;
      }

      .background {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: opacity 3s ease-in-out;
        position: absolute;
        opacity: 0;


        &.show {
          opacity: 1;
          z-index: -1;
          transition: none;
        }

        &:nth-of-type(5) {
          object-position: 50% 40%;
        }

        @media (max-width:700px) {

          &:nth-of-type(1),
          &:nth-of-type(4) {
            object-position: right;
          }

          &:nth-of-type(3) {
            object-position: 85% 0;
          }
        }
      }
    }

    h1,
    h5 {
      position: relative;
      z-index: 5;
      color: white;
      font-weight: normal;
      font-family: $arimo;
    }

    h5 {
      font-size: 25px;
    }
  }

  .first {
    padding-top: 100px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .who {
      width: 50%;
      max-width: 700px;

      p {
        font-family: $poppins;
        font-size: 1.75rem;
        margin: 25px 0;
      }


      .mobile-img {
        display: none;
      }
    }

    .images {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      img {
        margin: 10px;
        width: 70%;
      }
    }

  }

  .second {
    h1 {
      text-align: center;
    }

    p {
      font-size: 2rem;
    }
  }

  .join-us {
    background: #958FA7;
    color: white;
    width: 100%;

    &>.row-flex:first-child {
      width: 100%;

      h1 {
        color: white;
        display: flex;
        margin-top: -10px;
        display: inline-block;
        margin-right: 30px;
        text-wrap: wrap;
      }

      p {
        max-width: 750px;
        font-size: 1.5rem;
        display: block;
      }
    }

    .row-flex:nth-of-type(2) {
      justify-content: center;
      align-items: center;
      min-height: 425px;
      position: relative;

      &>div:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 50%;

        h3 {
          text-align: center;
          margin-bottom: 10px;
        }

        form {
          display: flex;
          flex-direction: column;
          width: 80%;

          * {
            font-family: $poppins;
          }

          .row-flex {
            justify-content: space-between;

            input {
              width: 50%
            }
          }

          input {
            background: none;
            border: 3px solid white;
            padding: 5%;
            border-radius: 20px;
            margin: 5px;

            &::placeholder {
              color: white;
              font-size: 1rem;
            }
          }

          button {
            color: #958FA7;
            background: white;
            padding: 5%;
            border-radius: 20px;
            margin: 5px;
            font-size: large;
          }
        }
      }

      &>div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .links {
          a {
            cursor: pointer;

            img {
              width: 80px;
              position: relative;
              height: auto;
              margin: 5px;
            }
          }
        }
      }

      &::after {
        content: "";
        background: white;
        position: absolute;
        width: 4px;
        height: 120%;
        left: 0;
        bottom: 0;
      }
    }


  }

  // General selectors

  h1 {
    color: $phil-blue;
    font-family: $bree;
    font-size: 4.5rem;
  }

  p {
    font-family: $poppins;
  }

  .row-flex {
    display: flex;
  }

  h3 {
    font-size: 2.1rem;
  }

  @media screen and (max-width: 700px) {

    .first {
      flex-direction: column;

      .who {
        width: 100%;
        margin: 0 auto;

        h1 {
          text-align: center;
        }

        .mobile-img {
          display: block;
          margin: 10px auto;
          width: 100%;
          height: 400px;
          object-fit: cover;
          object-position: 0 15%;
        }
      }

      .images {
        display: none;
      }
    }

    .join-us {
      &>.row-flex:first-child {
        flex-direction: column;
        align-items: center;

        h1 {
          line-height: 1;
          margin: 15px 0;
          justify-content: center;
          width: 100%
        }

        p {
          width: 100%
        }
      }

      h3 {
        margin: 25px 0;
      }

      &>.row-flex:nth-child(2) {
        flex-direction: column;

        &::after {
          display: none;
        }

        &>div:first-child {
          width: 100%;

          form {
            .row-flex {
              flex-direction: column;

              input {
                width: 100%;
              }
            }

            input {
              width: 100%;
            }

            button {
              width: 100%;
            }
          }

        }
      }
    }
  }


}