@import '../styles/placeholders';
@import '../styles/variables';

nav {
  background: none;
  z-index: 10;


  #hamburger {
    display: none
  }

  @extend %flex;
  align-items: center;
  padding: 20px 40px;
  width: 100%;

  .nav-left {
    @extend %flex;
    align-items: center;
    width: 25%;

    img {
      height: 70px;

      @media screen and (max-width: 500px) {
        height: 50px;
      }
    }

    h4 {
      text-transform: uppercase;
      font-family: $actor;
      color: $phil-blue;
      text-align: center;
      max-width: 240px;
    }

    .bar {
      display: none;
    }

    @media screen and (max-width: 1243px) {
      h4 {
        font-size: 20px;
      }
    }
  }

  .nav-mid {
    width: 50%;
    @extend %flex;
    justify-content: space-between;
    padding: 0px 20px;
    z-index: 999;

    a {
      background: none;
      font-size: 20px;
      font-family: $poppins;
      color: $phil-blue;
      font-weight: bold;
      text-decoration: none;
    }

    & a:last-child {
      display: none;
    }
  }

  .nav-right {
    width: 25%;
    @extend %flex;
    justify-content: flex-end;


    a {
      background: $phil-blue;
      text-decoration: none;
      color: white;
      font-size: 25px;
      width: 144px;
      height: 56px;
      font-family: $poppins;
      @extend %center;
      font-weight: 400;
    }
  }


  //Media queries
  @media screen and (max-width: $tablet) {
    height: 80px;

    .nav-left {
      width: 70%;

      h4 {
        font-size: 15px;
        max-width: 175px;
      }
    }

    .nav-mid {
      button {
        margin: 5% 0;
      }

      transform: translateX(100vw);
      flex-direction: column;
      position: fixed;
      height: 100vh;
      width: 220px;
      top: 80px+200px;
      right: 0;
      bottom: 0;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 2%;
      padding-left: 5%;
      transition: .3s ease-in-out all;

      & a:last-child {
        display: flex;
      }

    }

    .nav-mid.active {
      transform: translateX(0);
      position: absolute;
      display: flex;
      background: white;
    }

    .nav-right {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;

      a {
        display: none;
      }

      #hamburger {
        background: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 35px;
        height: 35px;
        position: relative;
      }

      .bar {
        display: block;
        height: 4px;
        width: 100%;
        margin: 3px 0;
        background: $phil-blue;
        position: absolute;
        transition: .08s ease-in-out all;
      }

      .bar:nth-of-type(1) {
        top: 0;
      }

      .bar:nth-of-type(3) {
        bottom: 0;
      }

      .bar.active {
        top: auto;
        bottom: auto;

        &:first-child {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          visibility: hidden;
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }

    }
  }
}