@import '../../styles/placeholders';
@import '../../styles/variables';
@import '../../styles/mixins';

section {
  .hero {
    position: relative;
    width: 100%;
    min-height: 74vh;
    background: url('../../media/phil-fishing.jpg');
    background-size: cover;
    background-position-y: 50%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 5%;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.654);
    }

    main {
      width: 90%;
      height: 80%;
      font-family: $arimo;
      max-width: 930px;


      .title {
        position: relative;
        color: white;
        text-align: right;

        h1 {
          font-weight: bold;
          font-size: 2.6rem;
          color: white;
        }

        h5 {
          font-size: 1.7rem;
          font-weight: normal;
          margin-bottom: 1.5%;
        }
      }

      a {
        @include btn(white, $phil-blue);
        display: inline-block;
        text-align: center;
        vertical-align: center;
        padding: 7px;
      }

      .title-desc {
        display: flex;
        color: white;
        position: relative;
        justify-content: center;
        align-items: end;
        margin-top: 5%;

        p {
          font-style: italic;
          width: fit-content;
          font-size: 1.7rem;
        }
      }
    }

  }

  .homepage-section {
    margin: 65px auto;

    .underline-title {
      font-family: $arimo;
      color: $phil-blue;
      font-size: 40px;
      text-align: center;
      margin: 70px 0;
      position: relative;
      display: flex;
      align-items: end;
      justify-content: center;
      font-style: italic;

      &::after {
        content: "";
        position: absolute;
        width: 200px;
        height: 3px;
        background: $phil-blue;
        bottom: -10px;
      }
    }

    main {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .card {
        background: white;
        max-width: 345px;
        margin: 2% 3%;
        height: 500px;
        border-radius: 51px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 30px;
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
        border-top: 9px solid $phil-blue;

        svg {
          font-size: 40px;
          padding: 2px;
          color: white;
          border-radius: 50%;
          background-color: $phil-blue;
        }

        h5,
        p {
          font-family: $poppins;
        }

        h5 {
          font-size: 25px;
          margin: 5px 0;
          color: $phil-blue;
          text-transform: capitalize;
          text-align: center;
        }

        p {
          font-size: 20px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    &:nth-child(3) {
      &>div:nth-of-type(1) {
        height: 112px;
        align-items: center;
        display: flex;

        img {
          height: 100px;
          width: auto;
        }

        h2 {
          font-family: $poppins;
          text-transform: uppercase;
          color: $phil-blue;
        }

      }

      .events {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .tile {
          background: #CBC5DB;
          border: 5px solid white;
          width: 364px;
          height: 393px;
          margin: 0 5px;
          padding: 15px;
          font-family: $poppins;
          color: $phil-blue;

          &:first-child {
            background: $phil-blue;
          }

          h1 {
            margin: 0;
            justify-content: start;
            font-family: inherit;
            font-style: inherit;
            font-weight: normal;

            &:after {
              display: none;
            }
          }

          p.bolded {
            font-weight: bold;
          }

          p {
            max-width: 200px;
            text-transform: capitalize;
            margin: 10px 0;
          }

          &:first-child {
            color: white;

            h1 {
              color: white;
            }
          }

        }

      }

      a {
        @include btn($phil-blue, white);
        display: flex;
        @extend %center;
        padding: 5px 10px;
        width: 120px;
        white-space: nowrap;
        margin: 15px auto;
        text-transform: uppercase;
        text-align: center;
      }
    }

    .events {
      p.disclaimer {
        color: gray;
        font-size: 25px;
        margin: 5px auto;
        text-align: center;
        font-family: $poppins;
      }
    }
  }

  @media (max-width: 700px) {

    .hero {
      align-items: center;
      text-align: center;

      main {
        .title {
          text-align: center;

          h5 {
            font-size: 20px;
            margin: 20px 0;
          }
        }

        .title-desc {
          margin-top: 40px;

          .quote {
            span {
              display: block;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .homepage-section {
      main {
        .card {
          margin: 5px 0;
        }
      }
    }
  }
}