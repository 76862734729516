@mixin btn($background, $textColor) {
  background: $background;
  color: $textColor;
  font-family: $poppins;
  text-decoration: none;
  font-weight: bold;
}

@mixin page-layout() {
  max-width: 2500px;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
}