@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');

//Colors
$phil-blue: #140149;

//Fonts
$actor: 'Actor', sans-serif;
$poppins: 'Poppins', sans-serif;
$arimo: 'Arimo', sans-serif;
$bree: 'Bree Serif', 'serif';
$knewave: 'Knewave', system-ui;

//Device Sizing
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;