@import '../../styles/placeholders';
@import '../../styles/variables';
@import '../../styles/mixins';

section#donate {

  h1 {
    font-size: 30px;
    background: $phil-blue;
    font-family: $poppins;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .donation {
    h2 {
      font-family: $poppins;
    }

    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;

  }

  .donation-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 1200px;
    padding-top: 100%;
    border: none;

    iframe {
      position: absolute;
      border: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%
    }
  }
}